import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Image from '../Image/Image'

function NotFoundImage() {
  const { hdd, hddStatic } = useStaticQuery(staticQuery)
  const [safari, setSafari] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    setLoaded(true)
  }, [])

  return (
    <ImageContainer style={{ opacity: loaded ? 1 : 0 }}>
      {safari ? (
        <Image src={hddStatic.childImageSharp.fluid} alt="" />
      ) : (
        <Image src={hdd.publicURL} />
      )}
    </ImageContainer>
  )
}

const staticQuery = graphql`
  {
    hdd: file(name: { regex: "/404-hdd/" }) {
      publicURL
    }
    hddStatic: file(name: { regex: "/404-hdd-static/" }) {
      childImageSharp {
        fluid(maxWidth: 256, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default NotFoundImage

const ImageContainer = styled.div`
  position: relative;
  width: 236px;
  height: 236px;
  transition: opacity 0.2s ease;
`

const AbsoluteImage = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
