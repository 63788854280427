import React, { useEffect, useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import media from '@styles/media'
import Heading from '@components/Heading'
import Layout from '@components/Layout'
import SEO from '@components/SEO'
import Section from '@components/Section'
import NotFoundImage from '@components/NotFoundImage/NotFoundImage'
import Gradient from '@components/Gradient/Gradient'

function NotFound({ data, location }) {
  const { seo } = data.allContentfulHomePage.edges[0].node
  const [keys, setKeys] = useState([])

  useEffect(() => {
    if (keys.includes('g') && keys.includes('h')) {
      navigate('/')
    }

    function handleModalShortcuts(event) {
      if (event.key === 'g') {
        event.preventDefault()
        setKeys([...keys, 'g'])
      }
      if (event.key === 'h') {
        event.preventDefault()
        setKeys([...keys, 'h'])
      }
    }

    document.addEventListener('keydown', handleModalShortcuts)
    return () => document.removeEventListener('keydown', handleModalShortcuts)
  }, [keys, setKeys])

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.file.url}
        pathname={location.pathname}
      />
      <Layout withFooter={false} withNav={false}>
        <Section hideOverflow>
          <Content>
            <NotFoundIcon />
            <Headings withoutTextShadow>
              <Gradient>Page not found</Gradient>
            </Headings>
            <Subheading>
              We can't seem to find the page you're looking for.
              <DesktopLink href="/">
                Press
                <Key style={{ margin: '0 1px 0 6px' }}>G</Key>{' '}
                <Key style={{ margin: '0 6px 0 1px' }}>H</Key>
                to go back to the home page.
              </DesktopLink>
              <MobileLink href="/">Go back home </MobileLink>
            </Subheading>
            <NotFoundImage />
          </Content>
        </Section>
      </Layout>
    </>
  )
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundPageQuery {
    allContentfulHomePage {
      edges {
        node {
          seo {
            title
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

const DesktopLink = styled(Link)`
  color: ${p => p.theme.colors.purple};
  display: block;

  ${media.tablet`
    display: none;
  `}
`

const MobileLink = styled(Link)`
  color: ${p => p.theme.colors.purple};
  margin: 24px 0 64px;
  text-decoration: underline;
  display: block;

  ${media.desktop_small_up`
    display: none;
  `}
`

const Content = styled.div`
  max-width: 1170px;
  padding-top: 12vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 1170px;
`

const Headings = styled(Heading.h1)`
  margin: 32px auto 16px;
`

const Subheading = styled.div`
  font-size: 18px;
  color: ${p => p.theme.colors.moon};
  margin-bottom: 54px;

  a {
  }

  ${media.phablet`
    max-width: 255px;
    margin: 0 auto;
  `}
`

const Key = styled.span`
  display: inline-flex;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #26272f;
  color: ${p => p.theme.colors.purple};
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const NotFoundIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="18" r="18" fill="white" fillOpacity="0.05" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.645 26.9939C11.3261 26.9952 11.0413 26.7948 10.9349 26.4942C10.8285 26.1936 10.9238 25.8586 11.1725 25.659L12.4923 24.5867C12.9825 24.1895 13.6689 24.1415 14.2096 24.4667C17.1259 26.176 20.8253 25.7228 23.2428 23.3601C25.6604 20.9975 26.1983 17.3095 24.5564 14.3547C22.9145 11.3999 19.4986 9.9089 16.2156 10.7139C12.9325 11.519 10.5939 14.421 10.5051 17.8002C10.4531 19.2849 10.856 20.7502 11.66 21.9996C11.8033 22.2259 11.815 22.5116 11.6906 22.7489C11.5662 22.9862 11.3246 23.1392 11.0569 23.1501C10.7892 23.1611 10.536 23.0283 10.3926 22.8019C9.42707 21.3002 8.94306 19.5394 9.00534 17.7552C9.11545 13.7012 11.9237 10.2218 15.863 9.25838C19.8024 8.29497 23.8994 10.0856 25.8681 13.6312C27.8367 17.1768 27.1905 21.6011 24.29 24.4355C21.3895 27.2699 16.9514 27.8139 13.4522 25.764L12.1174 26.8214C11.9845 26.9315 11.8176 26.9925 11.645 26.9939ZM18.7388 20.9944V17.2449C18.7388 16.8307 18.403 16.495 17.9889 16.495C17.5747 16.495 17.239 16.8307 17.239 17.2449V20.9944C17.239 21.4085 17.5747 21.7442 17.9889 21.7442C18.403 21.7442 18.7388 21.4085 18.7388 20.9944ZM17.239 14.9958C17.239 15.41 17.5747 15.7457 17.9889 15.7457C18.403 15.7457 18.7388 15.41 18.7388 14.9958C18.7388 14.5817 18.403 14.2459 17.9889 14.2459C17.5747 14.2459 17.239 14.5817 17.239 14.9958Z"
      fill="white"
    />
  </svg>
)
